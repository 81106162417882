<template>
    <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center" id="page-login">
        <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-4/5 sm:m-0 m-4">
            <div class="vx-card">
                <div class="vx-row">
                    <div class="vx-col hidden sm:w-full md:w-full lg:block lg:w-2/3 bg-image text-center p-8">
                        <img src="../../../assets/images/logo/forgot-password.png" width="90%" height="100%" alt="">
                    </div>
                    <div class="vx-col sm:w-full md:w-full lg:w-1/3 m-auto pl-8">
                        <div class="w-full" v-if="!isSendMail">
                             <div class="w-full pr-4 flex mt-4">
                                <span class="font-bold">Bạn quên mật khẩu?</span>
                            </div>
                            <div class="w-full pr-4 flex text-">
                                <span class="text-grey" style="font-size:12px">Vui lòng nhập email để nhận hướng dẫn cài đặt lại mật khẩu.</span>
                            </div>

                            <div class="w-full pr-4 mt-2">
                                <vs-input icon-pack="feather" icon="icon-mail" id="text-account" name="email" v-validate="'required|email'" class="w-full round" label="Email" v-model="email" placeholder="email@gmail.com"></vs-input>
                                <span class="text-danger text-sm">{{ errors.first('email') }}</span>
                            </div>
                            <div class="w-full mt-2 pr-4">
                                <vs-button class="w-full round" @click="forgotPassword" :disabled="!validateForm">Gửi</vs-button>
                                <vs-button icon-pack="feather" icon="icon-chevron-left" class="w-full mt-2 round" type="flat" @click="$router.push('/pages/login')">Quay lại trang đăng nhập</vs-button>
                            </div>
                        </div>
                        <div class="w-full pr-4" v-else>
                            <div class="w-full text-center">
                                <img src="../../../assets/images/logo/image_2021_06_18T09_47_43_889Z.png" width="30%" height="30%" alt="">
                            </div>
                            <div class="w-full">
                                <div class="w-full">
                                    <span class="mb-4 font-bold" style="font-size:15px">Xác nhận gửi yêu cầu thành công.</span>
                                </div>
                                <span class="text-left mt-4" style="color:#ccc">Gửi yêu cầu đặt lại mật khẩu thành công, vui lòng kiểm tra mail để xác thực!!</span>
                                <vs-button  class="w-full mt-4 round" @click="openGmail">Đi tới hòm thư</vs-button>
                                <vs-button icon-pack="feather" icon="icon-chevron-left" class="w-full mt-2 round" type="flat" @click="$router.push('/pages/login')">Quay lại trang đăng nhập</vs-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {

    },
    data(){
        return {
            email: null,
            isSendMail: false
        }
    },
    computed:{
        validateForm() {
            return !this.errors.any()
        },
    },
    methods: {
        openGmail: function(){
            window.open('https://mail.google.com/')
        },
        async forgotPassword(){
            const result = await this.$validator.validateAll();
            if (!result) return;
            const payload = {
                email: this.email
            }
            this.$vs.loading();
            this.$oauth.post('/registration/forgot-password', payload).then((res) => {
                if (res.data) {
                    this.isSendMail = true;
                }
                else {
                    this.$vs.notify({
                        color: 'danger',
                        text: `Email không tồn tại!`,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                    });
                }
                this.$vs.loading.close();
            }).catch(() => this.$vs.loading.close())
        }
    }
}
</script>

<style lang="scss">
.bg-image {
    height: 700px; /* You must set a specified height */
    background-color: #1c9863;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
    border-radius: 10px 0px 0px 10px;
    
}
</style>